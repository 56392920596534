<script>
import Octicons from "@primer/octicons";
import CustomIcons from "@/components/icons/CustomIcons";

export default {
  name: "Icon",
  functional: true,
  props: {
    name: { type: String, required: true },
    color: {
      type: "primary" | "secondary" | "default" | "success" | "danger" | "info" | "warning" | "inherit",
      default: "inherit",
    },
    scale: { type: Number, default: 1 },
    flip: { type: undefined | "vertical" | "horizontal", default: undefined },
    rotate: { type: String },
    spin: { type: Boolean, default: false },
  },
  render(h, context) {
    const isOcticon = context.props.name in Octicons;
    const octicon = isOcticon ? Octicons[context.props.name].heights[16] : {};

    let children = [];
    const domProps = {};
    if (context.children) children = context.children;
    else if (isOcticon) {
      children = [
        h("svg", {
          class: ["icon", context.props.name],
          attrs: {
            viewBox: octicon.options?.viewBox || undefined,
            "aria-hidden": "true",
          },
          domProps: {
            innerHTML: octicon.path || "",
          },
        }),
      ];
    } else {
      const iconString = CustomIcons[context.props.name]?.slice(4);
      if (iconString) domProps.innerHTML = "<svg class='icon'" + iconString;
    }

    return h(
      "div",
      {
        attrs: { id: context.data.id },
        class: [
          "icon-container",
          context.props.color,
          context.data.class,
          context.data.staticClass,
          {
            "flip-horizontal": context.props.flip === "horizontal",
            "flip-vertical": context.props.flip === "vertical",
            rotate: context.props.rotate !== undefined,
            spin: context.props.spin,
          },
        ],
        style: {
          "--scale": context.props.scale,
          "--rotate": context.props.rotate,
        },
        on: {
          click: context.listeners.click ?? (() => {}),
        },
        domProps,
      },
      children,
    );
  },
};
</script>

<style lang="scss">
@import "styles/colors/by-class";
.icon-container {
  @extend .color-by-class;
  display: flex;
  align-items: center;
  height: 1em;
  width: auto;
  aspect-ratio: 1;
  fill: currentColor;

  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  &:not(.inherit) .icon {
    fill: var(--bg-color);
  }

  .icon {
    all: unset;
    display: inline-block;
    vertical-align: text-top;
    overflow: visible;
    margin: 0;
    height: 100%;
    width: 100%;

    & > path {
      transform-origin: 50% 50%;
      pointer-events: none;
    }
  }

  &:not(.flip-horizontal):not(.flip-vertical) .icon {
    transform: scale(var(--scale));
  }

  &.flip-horizontal .icon {
    transform: scale(calc(-1 * var(--scale)), var(--scale));
  }

  &.flip-vertical .icon {
    transform: scale(var(--scale), calc(-1 * var(--scale)));
  }

  &.rotate {
    transform: rotate(var(--rotate));
  }

  &.spin .icon {
    animation: 1s linear 0s infinite rotating;
  }
}
</style>
